<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Supper partner Settings</p>
      <div v-if="$store.state.admin != ''" class="">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div
          v-if="!pageLoading"
          class="white text-center pt-4 pb-10 pa-2"
          id="percent"
          style="border-radius: 3px"
        >
          <v-layout
            row
            wrap
            id="customPercent"
            v-for="(levels, i) in supperLevels"
            :key="i"
          >
            <v-flex xs4 v-if="i == 0 && isAllowed == 1" class="mb-3 mt-4">
              <p class="green--text" v-if="isReleased == 0">% Released</p>
              <p class="red--text" v-else>% Not Released</p>
            </v-flex>
            <v-flex xs8 v-if="i == 0" class="mb-3">
              <p>
                <v-switch
                  @click="changeAllow"
                  :loading="allowLoading"
                  class="mr-5"
                  style="float: right; clear: right"
                  v-model="isAllowed"
                  label="Allow super partner"
                ></v-switch>
              </p>
            </v-flex>
            <v-flex xs12><br /> </v-flex>
            <v-flex xs4 class="pr-3 mt-1">
              <span>{{ levels.Level }}</span>
            </v-flex>
            <v-flex xs5 class="pr-3">
              <v-text-field
                style="font-size: 11px"
                v-model="levels.percent_value"
                :rules="[(v) => !!v || 'Required']"
                label="Percent %"
                required
                dense
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs3 class="pr-3">
              <v-btn small text class="" @click="changeLevel(levels)">
                <span class="text-capitalize blue--text">Save</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pageLoading: false,
      supperLevels: [],
      allowLoading: false,
      isAllowed: "",
      isReleased: "",
    };
  },

  methods: {
    // async saveConstants() {
    //   this.constantLoading = true;
    //   try {
    //     var changeConstatnsResult = await axios({
    //       method: "POST",
    //       url: this.$store.state.adminURL,

    //       data: {
    //         query: `mutation{
    //              changeConstants(
    //               brand_name:"${this.brandName}",
    //               phone:"${this.phoneNumber}",
    //               others:"${this.others}",
    //               ){
    //              statusMessage

    //              }
    //             }
    //             `,
    //       },
    //     });

    //     if (
    //       changeConstatnsResult.data.data.changeConstants[0].statusMessage.toString() ==
    //       "true"
    //     ) {
    //       this.constantDialog = false;
    //       this.getCurrentPercent();
    //     } else alert("No change, please try again");
    //   } catch (err) {
    //     alert(err);
    //   }
    //   this.constantLoading = false;
    // },
    async getSupperPartnerLevels() {
      this.pageLoading = true;
      try {
        var supperLevelsResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
                 getSupperPartnerLevels{
                   supper_partner_percent_id
                   Level
                   percent_value
                 }
                }
                `,
          },
        });

        this.supperLevels = supperLevelsResult.data.data.getSupperPartnerLevels;
      } catch (err) {
        alert(err);
      }

      try {
        var partnerResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
               getPartner {
                is_allowed
                is_released
               }
              }
              `,
          },
        });
        this.isAllowed = partnerResult.data.data.getPartner[0].is_allowed;
        this.isReleased = partnerResult.data.data.getPartner[0].is_released;
      } catch (err) {}

      this.pageLoading = false;
    },

    async changeAllow() {
      if (this.isAllowed) this.isAllowed = 1;
      else this.isAllowed = 0;
      this.allowLoading = true;
      try {
        var changeAllowResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
                       changeAllow(is_allowed:${this.isAllowed}){
                       statusMessage
                    }
              
              }
              `,
          },
        });

        if (
          changeAllowResult.data.data.changeAllow[0].statusMessage.toString() ==
          "true"
        ) {
        } else alert("Something went wrong");
      } catch (err) {
        alert(err);
      }

      this.allowLoading = false;
    },

    async changeLevel(level) {
      try {
        var changeLevelResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
                       changeLevel(
                        supper_partner_percent_id:${level.supper_partner_percent_id},
                        percent_value:${level.percent_value},
                       ){
                       statusMessage
                    }
              
              }
              `,
          },
        });

        if (
          changeLevelResult.data.data.changeLevel[0].statusMessage.toString() ==
          "true"
        ) {
          await this.getSupperPartnerLevels();
        } else alert("Something went wrong");
      } catch (err) {
        alert(err);
      }
    },
  },

  async created() {
    if (this.$store.state.admin != "") {
      if (Number(Date.now() - this.$store.state.sessionTime) > 8 * 60 * 1000) {
        this.$store.dispatch("loginAdmin", "");
        this.$router.push({ name: "login" });
      } else {
        this.$store.dispatch("sessionTime", Date.now());
      }
      await this.getSupperPartnerLevels();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#percent {
  max-width: 800px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

#numbers:hover {
  background-color: rgb(235, 228, 228);
}
#customPercent {
  max-width: 450px;
  margin: auto;
}
</style>
