<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Help</p>
      <div v-if="$store.state.admin != ''" class="" style="font-size: 13px">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div
          v-if="!pageLoading"
          class="white pa-10 pl-1 pr-1"
          id="report"
          style="border-radius: 3px"
        >
          <v-layout row wrap justify-space-around>
            <!-- <v-flex xs12 md5>
              <div id="chooseDate" class="ml-10 mr-10 pl-10">
                <h3 class="mt-6 grey--text text--darken-1 ml-10">
                  Search Game result
                </h3>
              </div>
            </v-flex> -->
          </v-layout>
          <v-form @submit.prevent="" id="">
            <v-layout
              row
              wrap
              class="mb-15 pa-3 pt-0"
              style="font-size: 14px"
              justify-space-around
            >
              <v-flex xs12 class="pt-3">
                <v-textarea
                  v-model="helpText"
                  :rules="[(v) => !!v || 'Required']"
                  label="Write Help"
                  auto-grow
                  outlined
                  rows="4"
                  row-height="15"
                ></v-textarea>
              </v-flex>
              <v-flex
                xs12
                class="text-center"
                v-for="(allVideo, i) in allVideos"
                :key="i"
              >
                <video
                  v-if="allVideo != '' && wichVideoToPlay == allVideo"
                  playsinline
                  style="pointer-events: none"
                  id="video"
                  width="100%"
                  autoplay
                  :src="allVideo"
                ></video>
                <a
                  @click="wichVideoToPlay = allVideo"
                  class="mr-3"
                  v-if="allVideo != ''"
                >
                  Play Video {{ i }}</a
                >
                <a right @click="allVideos.splice(i, 1)" v-if="allVideo != ''">
                  <v-icon class="red--text" right>cancel</v-icon></a
                >
              </v-flex>
              <v-flex xs12 v-if="video != ''" class="text-right">
                <video
                  v-if="video != ''"
                  playsinline
                  style="pointer-events: none"
                  id="video"
                  width="100%"
                  autoplay
                  :src="video"
                ></video>
                <a
                  @click="
                    video = '';
                    files = [];
                  "
                >
                  <v-icon class="red--text" right>cancel</v-icon></a
                >
              </v-flex>
              <v-flex xs12 class="text-center">
                <v-btn
                  :loading="videoLoading"
                  text
                  class="grey lighten-3"
                  @click="simulateInputfileClicked"
                >
                  <span class="text-capitalize">Select video</span>
                </v-btn>
              </v-flex>

              <v-flex xs12 class="text-center mt-10">
                <v-btn
                  small
                  text
                  class="primary"
                  type="submit"
                  @click="saveHelp"
                  :loading="helpLoading"
                >
                  <span class="text-capitalize">Save</span>
                </v-btn>
              </v-flex>
              <v-flex xs12>
                <input
                  type="file"
                  accept="video/*"
                  ref="selectVideo"
                  style="display: none"
                  @change="OnInputFile"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import VueBarcode from "vue-barcode";
import firebase from "firebase";

export default {
  data() {
    return {
      pageLoading: false,

      helpLoading: false,

      helpText: "",
      help: [],
      files: [],
      video: "",
      allVideos: [],
      wichVideoToPlay: "",

      videoLoading: false,
      videoUrl: "",
    };
  },

  methods: {
    async getHelp() {
      this.pageLoading = true;
      try {
        var helpResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
               getHelp {
                      help_tbl_id
                      help_text
                      video
                    
                    }
              
              }
              `,
          },
        });

        this.help = helpResult.data.data.getHelp;
        this.helpText = this.help[0].help_text;
        this.allVideos = this.help[0].video.split(",");
      } catch (err) {
        alert(err);
      }

      this.pageLoading = false;
    },

    async saveHelp() {
      if (this.helpText != "") {
        this.helpLoading = true;
        if (this.files.length > 0) {
          await this.uploadImage();

          this.allVideos.push(this.videoUrl);
        }

        try {
          var helpResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                        updateHelp(
                          help_text: "${this.helpText}",
                          video: "${this.allVideos.toString()}"
                          ){
                            statusMessage
                        }

              }
              `,
            },
          });

          var result = helpResult.data.data.updateHelp[0].statusMessage;
          if (result.toString() == "true") {
            alert("Changed successfully!");
            this.getHelp();
            this.files = [];
            this.video = "";
          } else if (result.toString() == "false") {
            alert("Some thing went wrong, try again");
          } else alert(result);
        } catch (err) {
          alert(err);
        }

        this.helpLoading = false;
      } else alert("Please write some help text");
    },

    simulateInputfileClicked() {
      this.$refs.selectVideo.click();
    },

    OnInputFile(e) {
      this.videoLoading = true;
      const files = e.target.files;
      this.files = [];
      Array.from(files).forEach((file) => this.addVideo(file));
    },

    async addVideo(file) {
      this.files.push(file);
      const reader = new FileReader();
      reader.onload = async (f) => {
        this.video = f.target.result;
      };
      reader.readAsDataURL(file);
      this.videoLoading = false;
    },

    async uploadImage() {
      var storageReference = firebase
        .storage()
        .ref("jodan/video" + Math.random() + ".mp4");

      await (await storageReference.put(this.files[0])).ref
        .getDownloadURL()
        .then((url) => {
          this.videoUrl = url;
        });
    },
  },

  async created() {
    if (this.$store.state.admin != "") {
      if (Number(Date.now() - this.$store.state.sessionTime) > 8 * 60 * 1000) {
        this.$store.dispatch("loginAdmin", "");
        this.$router.push({ name: "login" });
      } else {
        this.$store.dispatch("sessionTime", Date.now());
      }
      await this.getHelp();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}
.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}

#video {
  border-radius: 7px;
  border: none;
}
</style>
